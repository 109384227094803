import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import { ContentAreaProps } from "./ContentAreaProps";

const ContentArea = loadable(
    (): Promise<DefaultComponent<ContentAreaProps>> =>
        import(/* webpackChunkName: "ContentArea" */ "./ContentArea"),
);
const ContentAreaLoader = (props: ContentAreaProps): ReactElement => (
    <ContentArea {...props} />
);

export default ContentAreaLoader;
