import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import HeroProps from "./HeroProps";

const Hero = loadable(
    (): Promise<DefaultComponent<HeroProps>> =>
        import(/* webpackChunkName: "Hero" */ "./Hero"),
);
const HeroLoader = (props: HeroProps): ReactElement => <Hero {...props} />;
export default HeroLoader;
