import React, { ReactElement } from "react";

import Hero from "components/Hero";
import PageContent from "components/PageContent";
import { Wrapper } from "style/components/Page";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import StartPageProps from "./StartPageProps";

const StartPage = ({ hero, blocks }: StartPageProps): ReactElement => {
    const pageContentRef = React.useRef<HTMLDivElement>(null);

    function handleClick() {
        pageContentRef?.current?.scrollIntoView({
            behavior: "smooth",
        });
    }

    return (
        <Wrapper id="maincontent">
            {hero && (
                <Hero
                    key={`Hero`}
                    theme="black"
                    contentTheme="white"
                    actionTheme="coral"
                    {...hero}
                    showArrowdown={true}
                    handleClick={handleClick}
                    editAttributePrefix="HeroBlock."
                />
            )}
            <div ref={pageContentRef} style={{ width: "100%" }}>
                <PageContent
                    key={`Blocks`}
                    areaName="Blocks"
                    mainContent={blocks}
                    componentSelector={getComponentTypeForContent}
                />
            </div>
        </Wrapper>
    );
};

export default React.memo(StartPage);
