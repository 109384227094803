import React, { ReactElement } from "react";

import ContentArea from "components/ContentArea";

import { PageContentProps } from "./PageContentProps";

const PageContent = ({
    mainContent = [],
    children,
    areaName = "MainContent",
    className,
    componentSelector,
}: PageContentProps): ReactElement => {
    return (
        <>
            {children}
            <ContentArea
                className={className}
                items={mainContent}
                componentSelector={componentSelector}
                areaName={areaName}
            />
        </>
    );
};

export default React.memo(PageContent);
